<template>
  <iframe :src="url" style="border: none; height: 100%; width: 100%;"></iframe>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MonitorProxy',
  computed: {
    ...mapGetters(['serverAddress']),
    url () {
      return `${this.serverAddress}/monitor/server.php?action=proxy.list`
    }
  }
}
</script>
